import { Injectable } from '@angular/core';
import { LoadingService } from './loading.service';
import { map, tap, shareReplay, first } from 'rxjs/operators';
import { convertSnap } from '../utils/courseUtil';
import { ChapterModel } from '../model/course';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { addDoc, collection, collectionData, CollectionReference, doc, DocumentData, DocumentReference, DocumentSnapshot, Firestore, getDoc, setDoc, updateDoc } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ChapterService {

  chapterSubject = new BehaviorSubject<ChapterModel[]>(null);
  chapters$ = this.chapterSubject.asObservable();
  private chapters: ChapterModel[] = [];

  public get getChapters() {
    return [...this.chapters];
  }

  // public get getSingleChapter(chapterId:string){
  //   this.cha
  // }

  constructor(private loadingService: LoadingService, private firestore: Firestore) { }

  async addChapter(courseId: String, data, chapterId: string) {
    await this.loadingService.loadingOn();
    const documentRef: DocumentReference<DocumentData> = doc(this.firestore, `courses/${courseId}/chapters/${chapterId}`);
    await setDoc(documentRef, data);
    this.loadingService.loadingOff();
  }

  async editChapter(courseId: string, chapterId: string, data) {
    await this.loadingService.loadingOn();
    const documentRef: DocumentReference = doc(this.firestore, `courses/${courseId}/chapters/${chapterId}`);
    await updateDoc(documentRef, data);
    this.loadingService.loadingOff();

  }

  async fetchChapters(courseId: string) {
    const collectionRef = collection(this.firestore, `courses/${courseId}/chapters`);
    const result: DocumentData[] = await firstValueFrom(collectionData(collectionRef, { idField: 'id' }));
    const chapters = result.map((doc: DocumentData) => { return  doc  as ChapterModel });
    this.chapterSubject.next(chapters)

  }

  async fetchSingleChapter(courseId: string, chapterId: string) {
    const docRef = doc(this.firestore, `courses/${courseId}/chapters/${chapterId}`);
    const result: DocumentSnapshot<DocumentData> = await getDoc(docRef);
    const chapter = { ...result.data(), id: result.id } as ChapterModel;
    return chapter;
  }

}
