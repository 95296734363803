import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CourseResolver } from './services/course.resolver';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyIonicModule } from '@ngx-formly/ionic';
import { ChapterResolver } from './services/chapter.resolver';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { provideStorage, getStorage } from '@angular/fire/storage';


const firebaseConfig = {
  apiKey: "AIzaSyB_gGNHwPhUvfXUJU2XwNqB19eCLYsfcsw",
  authDomain: "wane-3e7a9.firebaseapp.com",
  databaseURL: "https://wane-3e7a9.firebaseio.com",
  projectId: "wane-3e7a9",
  storageBucket: "wane-3e7a9.appspot.com",
  messagingSenderId: "184438087483",
  appId: "1:184438087483:web:cd28ef5bafaf6fac5d611e",
  measurementId: "G-50F0D5VYBS"
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideMessaging(() => getMessaging()),
    provideStorage(() => getStorage()),

    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(
      {
        types: [
          { name: 'repeat' },
          // { name: 'repeatCourseCategory' },
          // { name: 'repeatCategory' },
        ],

      }
    ), FormlyIonicModule, BrowserAnimationsModule // storage
  ],
  providers: [
    ChapterResolver,
    CourseResolver,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
