import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor(private loadingController:LoadingController) { }

  async loadingOn(){
    const loading = await this.loadingController.create({
      message:'loading...'
    });
    loading.present();
  }

  async loadingOff(){
    console.log('off loading');
    await this.loadingController.dismiss();
  }


}
