
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable, of} from 'rxjs';
import {  ChapterModel } from '../model/course';
import { ChapterService } from './chapter.service';



@Injectable({
    providedIn: 'root'
})
export class ChapterResolver implements Resolve<ChapterModel> {

    constructor(private chapterService: ChapterService) {

    }

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ChapterModel> {
        const courseId= route.paramMap.get('courseId');
        const chapterId = route.paramMap.get('chapterId');
        const chapter =   await this.chapterService.fetchSingleChapter(courseId,chapterId);
        return chapter;
    }

}

