export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyB_gGNHwPhUvfXUJU2XwNqB19eCLYsfcsw",
    authDomain: "wane-3e7a9.firebaseapp.com",
    databaseURL: "https://wane-3e7a9.firebaseio.com",
    projectId: "wane-3e7a9",
    storageBucket: "wane-3e7a9.appspot.com",
    messagingSenderId: "184438087483",
    appId: "1:184438087483:web:cd28ef5bafaf6fac5d611e",
    measurementId: "G-50F0D5VYBS"
  }
};
