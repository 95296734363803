


import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from 'rxjs';
import { CoursesService } from './courses.service';
import { CourseModel } from '../model/course';



@Injectable()
export class CourseResolver implements Resolve<CourseModel> {

    constructor(private coursesService: CoursesService) {

    }

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CourseModel> {
        const courseId = route.paramMap.get('courseId');
        const course = await this.coursesService.fetchSingleCourse(courseId);
        return course;
    }

}

