import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from '../model/user';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { getAuth, signInWithEmailAndPassword } from '@angular/fire/auth';
import { doc, DocumentReference, Firestore, docSnapshots } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userBehavior = new BehaviorSubject(null);
  user$: Observable<UserModel> = this.userBehavior.asObservable();

  constructor(private router: Router, private firestore: Firestore) {
    const auth = getAuth();
    auth.onAuthStateChanged(
      async (user) => {
        if (user) {
          const materialDoc: DocumentReference = doc(this.firestore, 'admin/' + user.uid);
          const data = await firstValueFrom(docSnapshots(materialDoc));
          const userModel = { ...data.data(), id: data.id } as UserModel;
          this.userBehavior.next(userModel);

        } else {
          this.userBehavior.next(null);
        }
      }
    );

  }

  async emailPassrodSignin(email: string, password: string) {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password).then(() => {
      console.log('logedin');
      this.router.navigate(['/layout'])
    }).catch(err => console.log(err));
  }
  // async googleSignin() {
  //   const provier = new auth.GoogleAuthProvider();
  //   const authCredential =  auth.GoogleAuthProvider.credential();
  //   const credential = await this.afAuth.signInWithCredential(authCredential);

  //   return this.updateUserData(credential.user);
  // }

  async signOut() {
    const auth = getAuth();
    await auth.signOut();
    return this.router.navigate(['/']);
  }



}
