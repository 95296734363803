import { Injectable } from '@angular/core';
import { map, first, shareReplay, tap } from 'rxjs/operators';
import { CourseModel } from '../model/course';
import { convertSnap } from '../utils/courseUtil';
import { Observable, BehaviorSubject, firstValueFrom } from 'rxjs';
import { LoadingService } from './loading.service';
import { AuthService } from '../auth/auth.service';
import { RequestModel } from '../model/user';
import { addDoc, collection, collectionData, deleteDoc, doc, DocumentData, DocumentReference, DocumentSnapshot, Firestore, getDoc, query, setDoc, updateDoc, where } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {


  private courseSubject = new BehaviorSubject<CourseModel[]>([]);
  courses$ = this.courseSubject.asObservable();

  private studentCourseSubject = new BehaviorSubject<CourseModel[]>([]);
  studentCourses$ = this.studentCourseSubject.asObservable();

  private studentRequestSubject = new BehaviorSubject<RequestModel[]>([]);
  studentRequests$ = this.studentRequestSubject.asObservable();

  private studentExpCourseSubject = new BehaviorSubject(null);
  studentCourseEpxTime$ = this.studentExpCourseSubject.asObservable();

  constructor(private firestore: Firestore, private loadingService: LoadingService, private authService: AuthService) {
  }

  async fetchCourses(): Promise<CourseModel[]> {
    const collectionRef = collection(this.firestore, `courses`);
    const result: DocumentData[] = await firstValueFrom(collectionData(collectionRef, { idField: 'id' }));
    const courses = result.map((doc: DocumentData) => {
      return doc as CourseModel
    });
    this.courseSubject.next(courses);
    return courses;
  }

  async fetchSingleCourse(id: string): Promise<CourseModel> {
    const docRef = doc(this.firestore, `courses/${id}`);
    const result: DocumentSnapshot<DocumentData> = await getDoc(docRef);
    const course = { ...result.data(), id: result.id } as CourseModel;
    return course;
  }

  async fetchStudentSingleCourse(id: string): Promise<CourseModel> {
    const docRef = doc(this.firestore, `courses/${id}`);
    const result: DocumentSnapshot<DocumentData> = await getDoc(docRef);
    const course = { ...result.data(), id: result.id } as CourseModel;
    console.log(course);
    return course;

  }



  async getEnrolledStudent(courseId, studentId) {
    const docRef = doc(this.firestore, `courses/${courseId}/students/${studentId}`);
    const result: DocumentSnapshot<DocumentData> = await getDoc(docRef);
    return result.data();
  }


  async updateExpTime(courseId, studentId, exp) {
    try {
      const docRef = doc(this.firestore, `courses/${courseId}/students/${studentId}`);
      await updateDoc(docRef, { exp: new Date(exp) });
    } catch (e) {
      console.log(e);

    }
  }

  addCategory(name: String) {
    const collectionRef = collection(this.firestore, `categories`);
    addDoc(collectionRef, { name: name });
  }

  updateCategory(id: String, name: String) {
    const docRef = doc(this.firestore, `categories/${id}`);
    updateDoc(docRef, { "name": name });
  }
  deleteCategory(id: String) {
    const docRef = doc(this.firestore, `categories/${id}`);
    deleteDoc(docRef);
  }

  async addCourse(data, collectionId: string) {
    await this.loadingService.loadingOn();
    const docRef = doc(this.firestore, `courses/${collectionId}`);
    await setDoc(docRef, data);
    this.loadingService.loadingOff();
  }



  async updateCourse(courseId: string, data) {
    await this.loadingService.loadingOn();
    const docRef = doc(this.firestore, `courses/${courseId}`);
    await updateDoc(docRef, data);
    await this.loadingService.loadingOff();
  }

  async fetchStudentCourses(studentId: string): Promise<CourseModel[]> {
    const collectionRef = collection(this.firestore, `users/${studentId}/courses`);
    const result = await firstValueFrom(collectionData(collectionRef, { idField: 'id' }));
    console.log(result);
    const courses: CourseModel[] = result as CourseModel[];
    console.log(courses);
    console.log('^^^^^^^^^^^')
    this.studentCourseSubject.next(courses);
    return courses;

  }

  async fetchStudentRequests(studentId: string): Promise<RequestModel[]> {
    console.log(studentId)
    const collectionRef = collection(this.firestore, 'requests');
    const queryData = query(collectionRef, where('userId', '==', studentId));
    const result = await firstValueFrom(collectionData(queryData, { idField: 'id' }));
    console.log(result);
    const requests: RequestModel[] = result as RequestModel[];
    this.studentRequestSubject.next(requests);
    console.log(requests);
    console.log('^^^^^^^^^^^')
    return requests;
  }

  async fetchCourseById(id: string): Promise<CourseModel> {
    const docRef = doc(this.firestore, `courses/${id}`);
    const resutl: DocumentSnapshot<DocumentData> = await getDoc(docRef);
    const course = { ...resutl.data(), id: resutl.id } as CourseModel;
    return course;
  }

  async fetchStudentExpCourseTime(courseId: string, studentId: string) {
    console.log(courseId, '--------', studentId)
    const docRef: DocumentReference<DocumentData> = doc(this.firestore, `courses/${courseId}/students/${studentId}`);
    const result: DocumentSnapshot<DocumentData> = await getDoc(docRef);
    console.log('^^^^^^^^^');
    console.log(result.data());
    console.log('****************');
    if (result.exists()) {
      return result.data();
    } else {
      return null;
    }

  }

}
